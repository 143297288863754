export const AMAZON_API = 'ADMIN_API'
export const API_REQUEST = 'API_REQUEST'
export const API_SUCCESS = 'API_SUCCESS'
export const API_ERROR = 'API_ERROR'
export const AMAZON_SUCCESS = 'AMAZON_SUCCESS'
export const AMAZON_ERROR = 'AMAZON_ERROR'
export const CONNECT_SUCCESS = 'CONNECT_SUCCESS'
export const CONNECT_ERROR = 'CONNECT_ERROR'
export const PROFILE_LIST_SUCCESS = 'PROFILE_LIST_SUCCESS'
export const PROFILE_LIST_ERROR = 'PROFILE_LIST_ERROR'
export const LIST_CAMPAIGNS_SUCCESS = 'LIST_CAMPAIGNS_SUCCESS'
export const LIST_CAMPAIGNS_ERROR = 'LIST_CAMPAIGNS_ERROR'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR'
export const MAIN_ACC_LIST_SUCCESS = 'MAIN_ACC_LIST_SUCCESS'
export const MAIN_ACC_LIST_ERROR = 'MAIN_ACC_LIST_ERROR'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR'
export const USER_LOGOUT = 'USER_LOGOUT'
export const AMAZON_CONNECT_SUCCESS = 'AMAZON_CONNECT_SUCCESS'
export const AMAZON_CONNECT_ERROR = 'AMAZON_CONNECT_ERROR'
export const AMAZON_UNLINK_SUCCESS = 'AMAZON_UNLINK_SUCCESS'
export const AMAZON_UNLINK_ERROR = 'AMAZON_UNLINK_ERROR'
export const CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS'
export const CREATE_RULE_ERROR = 'CREATE_RULE_ERROR'
export const GET_LOADER = 'GET_LOADER'
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS"
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR"
export const SEARCH_PROFILE_SUCCESS = "SEARCH_PROFILE_SUCCESS"
export const SEARCH_PROFILE_ERROR = "SEARCH_PROFILE_ERROR"
export const DASHBOARD_PROFILELIST_SUCCESS = "DASHBOARD_PROFILELIST_SUCCESS"
export const DASHBOARD_PROFILELIST_ERROR = "DASHBOARD_PROFILELIST_ERROR"
export const GET_LOADER1 = "GET_LOADER1";